export const environment = {
  production: true,
  hmr: false,
  keys: {
    token: 'TOKEN_PROXY_KEY',
    refreshToken: 'REFRESH_TOKEN_PROXY_KEY',
    user: 'USER_PROXY_KEY',
  },
  config: {
    redirectToWhenAuthenticated: '/pages/admins',
    redirectToWhenUnauthenticated: '/auth/login',
    maxVideoSizeInMb: 200,
    autoRefreshInterval: 10000,
  },
  api: {
    baseUrl: 'https://api.agro.ligafacens.com/dev',
    auth: {
      login: '/auth/local',
    },
    users: {
      list: '/users',
      create: '/users',
      getById: '/users/{id}',
      update: '/users/{id}',
      delete: '/users/{id}',
      me: '/users/me',
    },
    userPassword: {
      changePassword: '/users/password',
      forgotPasswordEmail: '/users/password/forgot/email/{email}',
      validateForgotPasswordCode: '/users/password/validate/{token}',
      forgotResetPassword: '/users/password/reset/{resetPasswordCode}',
    },
    articles: {
      list: '/articles',
      create: '/articles',
      getById: '/articles/{id}',
      update: '/articles/{id}',
      delete: '/articles/{id}',
    },
    categories: {
      list: '/categories',
      create: '/categories',
      getById: '/categories/{id}',
      update: '/categories/{id}',
      delete: '/categories/{id}',
    },
    cultures: {
      list: '/cultures',
      create: '/cultures',
      getById: '/cultures/{id}',
      update: '/cultures/{id}',
      delete: '/cultures/{id}',
    },
    cooperators: {
      list: '/cooperative-members',
      create: '/cooperative-members',
      getById: '/cooperative-members/{id}',
      update: '/cooperative-members/{id}',
      delete: '/cooperative-members/{id}',
    },
    landExpenses: {
      list: '/land-expenses',
      getById: '/land-expenses/{id}',
    },
    medias: {},
    plantingExpenses: {
      list: '/planting-expenses',
      getById: '/planting-expenses/{id}',
    },
    reports: {
      list: '/reports',
      create: '/reports',
      getById: '/reports/{id}',
      update: '/reports/{id}',
      delete: '/reports/{id}',
    },
  },
};
